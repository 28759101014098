import {
  Avatar,
  Box,
  Button,
  Divider,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ListIcon from "@mui/icons-material/List";
import styles from "./clientecomponent.module.css";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { stringAvatar } from "../../util/avatar";

function ClienteComponent({ object }) {
  const [tooltipHandle, setTooltipHandle] = useState(false);
  const route = useNavigate();

  const { custommer, address } = object;

  const handleTooltip = () => {
    setTooltipHandle(!tooltipHandle);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      arrow={true}
      onClick={handleTooltip}
      open={tooltipHandle}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <Box className={styles.box}>
      <div className={styles.upperBox}>
        <Avatar
          className={styles.avatar}
          {...stringAvatar(custommer.name)}
          src={custommer.picture}
          sx={{ width: 56, height: 56 }}
        />
        <h2 className={styles.h2}>
          {custommer.name.split(" ")[0]} {custommer.name.split(" ")[1]}
        </h2>
        <a className={styles.a}>
          {address.city}-{address.uf}
        </a>
      </div>

      <Box className={styles.componentFooter}>
        {/* <HtmlTooltip
          title={
            <React.Fragment>
              <div className={styles.toolTipComponent}>
                <a className={styles.toolTipA} onClick={handleTooltip}>
                  Requerimento
                </a>
                <a
                  className={styles.toolTipA}
                  onClick={() => {
                    console.log("hipo");
                  }}
                >
                  Declaracao Hipossuficiencia
                </a>
                <a
                  className={styles.toolTipA}
                  onClick={() => {
                    route.push({
                      pathname: "declaracao/residencia",
                      query: object,
                    });
                  }}
                >
                  Declaracao Residencia
                </a>
              </div>
            </React.Fragment>
          }
        >
          <Button>
            <ListIcon />
          </Button>
        </HtmlTooltip> */}
        <span>Visualizar Processos</span>
      </Box>
    </Box>
  );
}

export default ClienteComponent;
