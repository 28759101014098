import { TextField } from "@mui/material";
import styles from "./index.module.css";
import InputMask from "react-input-mask";

import * as React from "react";
import axios from "axios";

const AddressInfoComponent = ({
  street,
  setStreet,
  setDistrict,
  district,
  city,
  setCity,
  uf,
  setUf,
  cep,
  setCep,
  houseNumber,
  setHouseNumber,
}) => {
  return (
    <div className={styles.mainDiv}>
      <span>Endereço</span>

      <div className={styles.sideToSideField}>
        <TextField
          className={styles.textPatternFullWidth}
          label={"Rua"}
          disabled={true}
          value={street}
        />
      </div>

      <div className={styles.sideToSideField}>
        <TextField
          className={styles.textPattern}
          disabled={true}
          label={"Bairro"}
          variant="outlined"
          value={district}
        />

        <InputMask
          mask={"99999-999"}
          value={cep}
          onChange={async (e) => {
            await setCep(e.target.value);
            const cepVar = e.target.value.replace("-", "");
            if (cepVar.length > 7 && !isNaN(Number(cepVar))) {
              const { data } = await axios.get(
                `https://viacep.com.br/ws/${cepVar}/json/`
              );

              setCity(data.localidade);
              setUf(data.uf);
              setDistrict(data.bairro);
              setStreet(data.logradouro);
            }
          }}
        >
          {() => (
            <TextField
              className={styles.textPattern}
              label={"CEP"}
              variant="outlined"
            />
          )}
        </InputMask>
      </div>

      <div className={styles.sideToSideField}>
        <TextField
          className={styles.textPattern}
          label={"Cidade"}
          variant="outlined"
          disabled={true}
          value={city}
        />

        <TextField
          className={styles.textPattern}
          label="Estado"
          variant="outlined"
          disabled={true}
          value={uf}
        />
      </div>
      <div className={styles.sideToSideField}>
        <TextField
          className={styles.textPattern}
          label={"Número da Casa"}
          variant="outlined"
          value={houseNumber}
          inputProps={{
            inputMode: "numeric",
            maxLength: 4,
          }}
          onChange={(e) => {
            setHouseNumber(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default AddressInfoComponent;
