import styles from "./residencia.module.css";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
// import { handleGerarPDF } from "../../../../util/htmlHandler";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import imgTeste from "../../../../assets/pf.jpg";
import DraggableText from "../../../../components/TesteComponent/";
import DraggableImage from "../../../../components/TesteComponent/imageDragglable";
function Dec_Residencia(props) {
  const ref = useRef(null);
  const route = useNavigate();
  // console.log(props);
  const name = "route.query.name";
  const nationality = "brasileira";
  const rg = "5555555";
  const cpf = "5555555";
  const rua = "route.query.address";
  const bairro = "tal";
  const CEP = "111111";
  const cidade = "11111111";
  const UF = "RS";
  const numero = 11111;

  const text = `Eu, ${name},
   ${nationality}, RG ${rg} ,
   inscrita(o) no CPF ${cpf}, 
   residente e domiciliada na Rua ${rua}, 
   nº ${numero}, 
   Bairro ${bairro}, 
   CEP ${CEP}, 
   ${cidade}, 
   ${UF}, 
   declaro, que mantenho residência e domicílio neste endereço.
    `;

  const handleGerarPDF = () => {
    const input = ref.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Calcula a largura e altura do PDF
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Adiciona a imagem ao PDF
      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        pdfWidth,
        pdfHeight,
        undefined,
        "FAST"
      );

      // Salva o PDF
      pdf.save("meu-arquivo.pdf");
    });
  };
  const [draggableText, setDraggableText] = useState([]);

  function addItem() {
    const newItem = <DraggableText key={draggableText.length} />;
    setDraggableText((prevItems) => [...prevItems, newItem]);
  }

  return (
    <div className={styles.body}>
      <div>
        <button
          onClick={() => {
            handleGerarPDF(ref);
          }}
        >
          gerar
        </button>
        <button
          onClick={() => {
            addItem();
          }}
        >
          mais text
        </button>
      </div>
      <div>
        <div ref={ref} className={styles.doc}>
          <div
            style={{ width: "100%", height: 80, backgroundColor: "red" }}
          ></div>
          {draggableText}
          <span>{text}</span>
          <DraggableImage imga={imgTeste} />
        </div>
      </div>
    </div>
  );
}

export default Dec_Residencia;
