/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from "@mui/material";
import React, { useState } from "react";
import "./index.css";
import NavProfileComponent from "./NavProfileComponent/index.jsx";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isLogged, setIsLogged] = useState(cookies.get("token") ? true : false);

  const unlog = () => {
    cookies.remove("token");
    cookies.remove("name");
    setIsLogged(false);
    navigate("/home");
    setIsOpen(false);
  };

  return (
    <div className="Navbar">
      <Link href="/home" className="nav-logo">
        <span>Visual Law</span>
      </Link>
      <div className={`nav-items ${isOpen && "open"}`}>
        <a href="/home">Dashboard</a>
        <a href="/visual-law/clientes">Meus Clientes</a>
        <a href="/visual-law/declaracao/residencia">Serviços</a>
        <a href="/visual-law/templates">Templates</a>
        {isLogged ? (
          <NavProfileComponent unlog={unlog} />
        ) : (
          <a href={"/login"}>Logar</a>
        )}
      </div>

      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default Navbar;
