import { TextField } from "@mui/material";
import styles from "./index.module.css";
import InputMask from "react-input-mask";
import { useState } from "react";

import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const PersonalInfoComponent = ({
  name,
  setName,
  document,
  setDocument,
  rg,
  setRg,
  phoneNumber,
  setPhoneNumber,
  setMobilePhoneNumber,
  mobilePhoneNumber,
}) => {
  const [isCpf, setIsCpf] = useState(true);

  return (
    <div className={styles.mainDiv}>
      <span>Informações Pessoais</span>

      <div className={styles.sideToSideField}>
        <TextField
          className={styles.textPatternFullWidth}
          label="Nome Completo"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>

      <div className={styles.sideToSideField}>
        <InputMask
          mask="(99) 9999-9999"
          maskChar="_"
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
        >
          {() => (
            <TextField
              className={styles.textPattern}
              label="Telefone"
              variant="outlined"
            />
          )}
        </InputMask>
        <InputMask
          mask="(99) 9 9999-9999"
          maskChar="_"
          value={mobilePhoneNumber}
          onChange={(e) => {
            setMobilePhoneNumber(e.target.value);
          }}
        >
          {() => (
            <TextField
              className={styles.textPattern}
              label="Celular"
              variant="outlined"
            />
          )}
        </InputMask>
      </div>
      <div
        style={{
          display: "block",
        }}
      >
        <div
          style={{
            marginLeft: 20,
            justifyContent: "flex-start",
            display: "flex",
          }}
        ></div>
        <div className={styles.sideToSideField}>
          <InputMask
            mask={isCpf ? "999.999.999-99" : "99.999.999/9999-99"}
            maskChar="_"
            value={document}
            onChange={(e) => {
              setDocument(e.target.value.replace(/\D/g, ""));
            }}
          >
            {() => (
              <TextField
                className={styles.textPattern}
                label={isCpf ? "CPF" : "CNPJ"}
                variant="outlined"
              />
            )}
          </InputMask>

          <InputMask
            mask="99.999.999-*"
            maskChar="_"
            value={rg}
            onChange={(e) => {
              setRg(e.target.value.replace(/[^0-9X]/gi, ""));
            }}
          >
            {() => (
              <TextField
                className={styles.textPattern}
                label="RG"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
        <div style={{ marginLeft: 15 }}>
          <FormControl>
            <RadioGroup
              row
              value={isCpf ? "cpf" : "cnpj"}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="cpf"
                control={<Radio />}
                label="CPF"
                onClick={() => {
                  setIsCpf(true);
                  setDocument("");
                }}
              />
              <FormControlLabel
                value="cnpj"
                control={<Radio />}
                label="CNPJ"
                onClick={() => {
                  setIsCpf(false);
                  setDocument("");
                }}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoComponent;
