import React, { useState } from "react";
import Draggable from "react-draggable";
import imgTeste from "../../assets/pf.jpg";

const DraggableImage = (img) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  return (
    <Draggable onDrag={handleDrag} bounds={"parent"}>
      <div
        style={{
          position: "absolute",
          top: position.y,
          left: position.x,
        }}
      >
        <img
          src={imgTeste}
          alt="Picture of the author"
          width={300}
          height={300}
          style={{ zIndex: -1 }}
        />
      </div>
    </Draggable>
  );
};

export default DraggableImage;
