import styles from "./clientes.module.css";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ClienteComponent from "../../../components/ClientComponent/";
import { api } from "../../../service/api";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import MainContainer from "../../../components/MainContainer/index";
import Cookies from "universal-cookie";

function Clientes() {
  const cookies = new Cookies();
  const [userList, setUserList] = useState([]);

  // const userList = [
  //   { name: "Gustavo Silva", address: "seila" },
  //   { name: "Tadeu Brasil", address: "seila1" },
  //   {
  //     name: "Thiago Melo",
  //     address: "seila2",
  //     image: "https://pbs.twimg.com/media/FiRRfNfXEAMCwmk.jpg",
  //   },
  //   {
  //     name: "Rafael Ribeiro",
  //     address: "Casa 2",
  //     image:
  //       "https://pbs.twimg.com/media/Fcn9nCkXEAEOLt-?format=jpg&name=large",
  //   },
  //   {
  //     name: "Lucas Silva",
  //     address: "seila2",
  //     image:
  //       "https://play-lh.googleusercontent.com/p7rx-TDw8mSXmnN5oreMbOrC6FTumoRsnz8rDxUHL6-7xYtLlzcyj1GS8UKyBx5eJg",
  //   },
  // ];

  useEffect(() => {
    const getCustommers = async () => {
      console.log(cookies.get("token"));
      const res = await api.get("/custommer/list", {
        headers: { Authorization: `Bearer ${cookies.get("token")}` },
      });

      setUserList(res.data);
    };

    getCustommers();
  }, []);

  const [visibility, setVisibility] = useState(false);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: "black",
    "&:hover": {
      backgroundColor: red[900],
    },
    borderRadius: 100,
    height: 70,
    width: 70,
  }));

  return (
    <MainContainer>
      {/* <h1 className={styles.h1}>a</h1> */}

      <div className={styles.gridComponent}>
        <div className={styles.subGridComponent}>
          <Grid
            container
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            rowSpacing={4}
            sx={{ justifyContent: "center", display: "flex" }}
          >
            {userList.map((user, index) => {
              return (
                <Grid item key={index}>
                  <ClienteComponent object={userList[index]} key={index} />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>

      <div className={styles.pageFooter}>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginRight: 50,
            marginBottom: 50,
          }}
        >
          <ColorButton
            variant="contained"
            onClick={() => {
              console.log("hello");
            }}
          >
            <AddIcon
              style={{
                color: "white",
              }}
            />
          </ColorButton>
        </div>
      </div>
    </MainContainer>
  );
}

export default Clientes;
