import React from "react";
import NavBar from "./Navbar";

export default function MainContainer(props) {
  return (
    <>
      <NavBar />
      {props.children}
    </>
  );
}
