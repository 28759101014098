import styles from "./index.module.css";
import advocIcon from "../../../assets/advo-icon.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../../../service/api";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import { toast } from "react-toastify";

import PersonalInfoForm from "../../../components/Forms/PersonalInfoComponent/";
import AddressInfoForm from "../../../components/Forms/AddressInfoComponent/";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LoginInfoComponent from "../../../components/Forms/LoginInfoComponent";

export default function Registrar() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [street, setStreet] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [cep, setCep] = useState("");
  const [userDocument, setUserDocument] = useState("");
  const [rg, setRg] = useState("");
  const [uf, setUf] = useState("");
  const [email, setEmail] = useState("");
  const [checker, setChecker] = useState(false);

  // "profession": "Engenheiro de alguma coisa",
  // "motherName": "Maria Silva",
  // "nationality": "brasileira",
  // "birthday": "02/08/2002",
  // "civilState": "Solteiro"

  const navigate = useNavigate();

  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (cep.length == 8) {
      const getAddressInfo = async () => {
        try {
          const { data } = await axios.get(
            `https://viacep.com.br/ws/${cep}/json/`
          );
          setStreet(data.logradouro);
          setCity(data.localidade);
          setUf(data.uf);
          setDistrict(data.bairro);
        } catch (err) {
          toast.error("CEP não encontrado", {
            position: "bottom-right",
          });
        }
      };

      getAddressInfo();
    }
  }, [checker]);

  const nextStep = (validator) => {
    const currentIndex = index;
    if (currentIndex == 0 && personalInfoValidator() === true) {
      setIndex(currentIndex + 1);
      toast.success("Estamos quase lá!", {
        autoClose: 5000,
        position: "bottom-right",
      });
    } else if (currentIndex == 1 && addressValidator() === true) {
      setIndex(currentIndex + 1);
      toast.success("Estamos quase lá!", {
        autoClose: 5000,
        position: "bottom-right",
      });
    } else {
      toast.error("Preencha todos os campos antes de prosseguir", {
        autoClose: 5000,
        position: "bottom-right",
      });
    }
  };

  const previousStep = () => {
    const currentIndex = index;

    if (currentIndex > 0) {
      setIndex(currentIndex - 1);
    } else {
      console.log("we are done");
    }
  };

  const personalInfoValidator = () => {
    if (
      ["", null, undefined].includes(name) ||
      name.length < 6 ||
      ["", null, undefined].includes(phoneNumber) ||
      phoneNumber.length < 8 ||
      ["", null, undefined].includes(mobilePhoneNumber) ||
      mobilePhoneNumber.length < 8 ||
      ["", null, undefined].includes(userDocument) ||
      userDocument.length < 8 ||
      ["", null, undefined].includes(rg) ||
      rg.length < 6
    ) {
      return false;
    }
    return true;
  };

  const addressValidator = () => {
    if (
      ["", null, undefined].includes(street) ||
      street.length < 1 ||
      ["", null, undefined].includes(city) ||
      city.length < 1 ||
      ["", null, undefined].includes(district) ||
      district.length < 1 ||
      ["", null, undefined].includes(houseNumber) ||
      houseNumber.length < 1 ||
      ["", null, undefined].includes(cep) ||
      cep.length < 6
    ) {
      return false;
    }
    return true;
  };

  const registrar = async () => {
    try {
      const res = await api.post("/lawyer/create", {
        password,
        city,
        uf,
        houseNumber: Number(houseNumber),
        street,
        zipCode: cep,
        district,
        name,
        phoneNumber,
        email,
        document: userDocument,
      });

      if (res.status === 201) {
        toast.success("Seja bem vindo!", {
          position: "bottom-right",
        });
        navigate("/home");
      }
    } catch (err) {
      toast.error(JSON.parse(err.response.data.error), {
        position: "bottom-right",
      });
      setIndex(0);
    }
  };

  const form = [
    <PersonalInfoForm
      name={name}
      setName={setName}
      rg={rg}
      setRg={setRg}
      document={userDocument}
      setDocument={setUserDocument}
      mobilePhoneNumber={mobilePhoneNumber}
      setMobilePhoneNumber={setMobilePhoneNumber}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      nextStep={nextStep}
    />,

    <AddressInfoForm
      street={street}
      setStreet={setStreet}
      cep={cep}
      setCep={setCep}
      city={city}
      setCity={setCity}
      uf={uf}
      setUf={setUf}
      district={district}
      setDistrict={setDistrict}
      houseNumber={houseNumber}
      setHouseNumber={setHouseNumber}
    />,
    <LoginInfoComponent
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
    />,
  ];

  return (
    <div className={styles.container}>
      <div className={styles.containerDiv}>
        <div className={styles.imageContainer}>
          <div className={styles.imageSubContainer}>
            <img
              src={advocIcon}
              className={styles.img}
              alt="icon"
              onClick={nextStep}
            />
          </div>
        </div>
        <div className={styles.blockContent}>
          {form[index]}

          <div
            className={
              index === form.length - 1
                ? styles.roundedTest
                : styles.registerFooter
            }
          >
            <div className={styles.roundedButton} onClick={previousStep}>
              <ChevronLeftIcon className={styles.iconStep} />
            </div>
            <div className={styles.roundedButton} onClick={nextStep}>
              <ChevronRightIcon className={styles.iconStep} />
            </div>

            <Button className={styles.buttonConfirm} onClick={registrar}>
              <span>Confirmar</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
