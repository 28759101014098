import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/";
import Login from "./pages/login/";
import LawyerRegister from "./pages/registrar/advogado";
import VisualClientes from "./pages/visual-law/clientes";
import VisualResidencia from "./pages/visual-law/declaracao/residencia/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registro/advogado" element={<LawyerRegister />} />
        <Route path="/visual-law/clientes" element={<VisualClientes />} />

        <Route
          path="/visual-law/declaracao/residencia"
          element={<VisualResidencia />}
        />
      </Routes>
    </Router>
  );
}

export default App;
