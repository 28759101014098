import React, { useState } from "react";
import Draggable from "react-draggable";

const DraggableText = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  return (
    <Draggable onDrag={handleDrag} bounds={"parent"}>
      <div
        style={{
          position: "absolute",
          top: position.y,
          left: position.x,
          padding: "20px",
          backgroundColor: "#8b1313",
          height: "30px",
        }}
        onClick={() => {
          console.log("clicked");
        }}
      >
        <button>clique</button>
        <p>OLÁ MUNDO</p>
      </div>
    </Draggable>
  );
};

export default DraggableText;
