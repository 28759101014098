import styles from "./index.module.css";
import { TextField, Button, Divider } from "@mui/material/";
import advocIcon from "../../assets/advo-icon.png";
import { useState, React } from "react";
import { api } from "../../service/api/index";
import MainContainer from "../../components/MainContainer";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [seePass, setSeePass] = useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();

  const login = async () => {
    try {
      const { data } = await api.post("/auth", {
        username,
        password,
      });

      const expireDate = new Date();
      expireDate.setTime(expireDate.getTime() + 60 * 60 * 1000);

      cookies.set("token", data.accessToken, {
        expires: expireDate,
        onExpire: function (key, value) {
          console.log("O cookie expirou!");
        },
      });
      cookies.set("name", data.user.name, {
        expire: 1 / 24,
        onExpire: function (key, value) {
          console.log("O cookie expirou!");
        },
      });
      navigate("/home");
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  const handleRegister = () => {
    navigate("/registro/advogado");
  };

  return (
    <MainContainer>
      <div className={styles.container}>
        <div className={styles.containerDiv}>
          <div className={styles.image}>
            <div>
              <img src={advocIcon} alt={"icon"} height={100} width={100} />
              <br />
              <br />
              <span>Advogado</span>
            </div>
          </div>
          <div className={styles.fields}>
            <div className={styles.input}>
              <TextField
                id="outlined-basic"
                label="Email / CPF"
                variant="outlined"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                fullWidth
              />
            </div>
            <br />
            <div className={styles.input}>
              <TextField
                id="outlined-basic"
                label="Senha"
                variant="outlined"
                fullWidth
                type={seePass == true ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Checkbox defaultChecked />
              <VisibilityIcon fontSize="90" />
            </div> */}
            </div>
            <>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  marginTop: "32px",
                  backgroundColor: "black",
                  fontSize: "16px",
                  width: 100,
                  alignSelf: "center",
                }}
                onClick={login}
              >
                <span>entrar</span>{" "}
              </Button>
            </>
            <div className={styles.footer}>
              <span onClick={handleRegister}>ainda não tem uma conta?</span>
              <span>esqueci minha senha</span>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}
