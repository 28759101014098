import { TextField } from "@mui/material";
import styles from "./index.module.css";
import { useState } from "react";

import * as React from "react";
import Radio from "@mui/material/Radio";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";

const LoginInfoComponent = ({ email, password, setEmail, setPassword }) => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(true);
  const [confirmPassVisible, setConfirmPassVisible] = useState(true);

  return (
    <div className={styles.mainDiv}>
      <span>Login na Plataforma</span>

      <div className={styles.sideToSideField}>
        <TextField
          className={styles.textPatternFullWidth}
          label="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>

      <div className={styles.sideToSideField}>
        <TextField
          className={styles.textPattern}
          label="Senha"
          type={visible ? "password" : "text"}
          value={password}
          onChange={(e) => {
            if (e.target.value.indexOf(" ") !== -1) {
              return; // Retorna sem atualizar o estado
            }
            setPassword(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VisibilityIcon
                  onClick={() => {
                    setVisible(!visible);
                  }}
                />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          className={styles.textPattern}
          label="Confirmar Senha"
          type={confirmPassVisible ? "password" : "text"}
          value={confirmPassword}
          onChange={(e) => {
            if (e.target.value.indexOf(" ") !== -1) {
              return; // Retorna sem atualizar o estado
            }
            setConfirmPassword(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VisibilityIcon
                  onClick={() => {
                    setConfirmPassVisible(!confirmPassVisible);
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default LoginInfoComponent;
