/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import "./index.css";
import logo from "../../../../assets/pf.jpg";

const NavProfileComponent = ({ unlog }) => {
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  return (
    <div
      style={{
        margin: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      onClick={() => setIsOpenProfile(!isOpenProfile)}
    >
      <div className="container">
        <img src={logo} alt="Logo" className="profile-icon" />

        {isOpenProfile && (
          <div className="menu">
            <div>
              <span>Meu Perfil</span>
            </div>
            <div>
              <span>Meu Perfil</span>
            </div>
            <div>
              <span>Meu Perfil</span>
            </div>
            <div onClick={() => unlog()}>
              <span>Sair</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavProfileComponent;
